import React from 'react'
import '../style/Boton.css'

export default function Boton({text, clicButton, manageClic}) {
  return (
    <button 
    className = { clicButton ? 'counter' : 'restart' } 
    onClick ={manageClic}>
        {text}
    </button>
  )
}
