import './App.css';
import logo from './assets/logoCBC.png'
import Boton from './components/Boton';
import Counter from './components/Counter';
import { useState } from 'react'

function App() {

  const [numClics, setNumClics] = useState(0)

  const manageClic = () => {
    setNumClics (numClics +1);
  }

  const restartClic = () => {
    setNumClics (0);
  }
  return (
    <div className="App">
      <img 
        className='logo'
        src= { logo }  
        alt='logo'  
      />
      <Counter 
      numClics = {numClics}
      />
      <Boton
        text='Clic' 
        clicButton = {true}
        manageClic={manageClic}

      />
     <Boton
        text='Cleear' 
        clicButton = {false}
        manageClic={restartClic}

      />
      
    </div>
  );
}

export default App;
