import React from 'react'
import '../style/Counter.css'

export default function Counter( { numClics } ) {
  return (
    <div className='Counter'>
            <h1>{numClics}</h1>
    </div>

  )
}
